import { PropsWithChildren } from "react";
import { Button } from "@biblioteksentralen/js-utils";
import { LinkButton } from "@libry-content/types";
import { PortableTextTypeComponentProps } from "@portabletext/react";
import { ts } from "@libry-content/localization";
import { Link } from "@chakra-ui/layout";

type Props = PropsWithChildren<PortableTextTypeComponentProps<LinkButton>>;

export function TextLinkButtonSerializer({ value }: Props) {
  if (!ts(value?.label) || !value?.url) return null;

  return (
    <Link href={value.url} display="block" textAlign="center">
      <Button variant="secondary">{ts(value.label)}</Button>
    </Link>
  );
}
