import { MouseEventHandler } from "react";
import {
  Box,
  colors,
  Heading,
  ResponsiveValue,
  StyleProps,
  Text,
  useBreakpointValue,
  VStack,
} from "@biblioteksentralen/js-utils";

import SanityImage, { GalleryImage as GalleryImageType } from "../SanityImage";
import { ts } from "@libry-content/localization";
import { safelyGetImageDimensions } from "../../utils/safelyGetImageDimensions";

type ImageType = "highlight" | "thumbnail";

type Props = StyleProps & {
  image?: GalleryImageType;
  onClick?: MouseEventHandler<HTMLDivElement>;
  width?: ResponsiveValue<string>;
  height?: ResponsiveValue<string>;
  type?: ImageType;
};

const GalleryImage = ({
  image,
  onClick,
  width = "100%",
  height = "100%",
  type = "thumbnail",
  ...styleProps
}: Props) => {
  const maxTruncatedLines = useBreakpointValue({ base: 2, sm: 3 });
  const isThumbnail = type === "thumbnail";

  if (!image) return null;
  const { aspectRatio } = safelyGetImageDimensions(image);

  const hasTitle = !!ts(image.title);
  const hasDescription = !!ts(image.description);
  const hasTextContent = hasTitle || hasDescription;

  const nTitleLines = isThumbnail ? (hasDescription ? 1 : 2) : undefined;
  const nDescriptionLines =
    isThumbnail && maxTruncatedLines ? (hasTitle ? maxTruncatedLines - 1 : maxTruncatedLines) : undefined;

  const resolution = isThumbnail ? 300 : 1000;
  const imageHeight = Math.round(resolution / aspectRatio);

  return (
    <VStack marginInlineStart="0 !important" alignItems="flex-start" onClick={onClick} {...styleProps}>
      <Box width={width}>
        <Box
          height={height}
          position="relative"
          borderRadius={hasTextContent && !isThumbnail ? "0.5rem 0.5rem 0 0" : "0.5rem"}
          overflow="hidden"
          border={`1px solid ${colors.grey15}`}
        >
          <SanityImage src={image} width={resolution} height={imageHeight} borderRadius="0" blurQuality={25} />
        </Box>
        {hasTextContent && (
          <Box
            padding={isThumbnail ? "0.5rem 0.5rem 0" : "1rem"}
            width="100%"
            marginTop="0 !important"
            marginBottom={isThumbnail ? "-0.5rem" : undefined}
            background={isThumbnail ? undefined : colors.white}
            border={isThumbnail ? undefined : `1px solid ${colors.grey15}`}
            borderTop="none"
            borderRadius={hasTextContent && !isThumbnail ? "0 0 0.5rem 0.5rem" : "0.5rem"}
          >
            {hasTitle && (
              <Heading
                as="h4"
                fontSize={{ base: "sm", md: "md" }}
                fontWeight={{ base: undefined, md: 700 }}
                noOfLines={nTitleLines}
              >
                {ts(image.title)}
              </Heading>
            )}
            {hasDescription && (
              <Text
                fontSize={isThumbnail ? "sm" : "md"}
                noOfLines={nDescriptionLines}
                marginTop={isThumbnail ? undefined : "0.5rem"}
              >
                {ts(image.description)}
              </Text>
            )}
          </Box>
        )}
      </Box>
    </VStack>
  );
};

export default GalleryImage;
