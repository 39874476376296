import { RichTextBlock, ExtraRichTextBlock } from "@libry-content/types";
import styled from "@emotion/styled";
import { PortableText, PortableTextReactComponents } from "@portabletext/react";
import { TextTableSerializer } from "./TextTableSerializer";
import { Link } from "@chakra-ui/react";
import { colors } from "@biblioteksentralen/js-utils";
import { TextGallerySerializer } from "./TextGallerySerializer";
import { TextLinkButtonSerializer } from "./TextLinkButtonSerializer";
import VideoEmbedSerializer from "./VideoEmbedSerializer";

export const RichTextStyle = styled.div`
  max-width: 100%;
  > {
    * {
      max-width: 30rem;
      margin-left: auto;
      margin-right: auto;
    }
    p {
      line-height: 1.6;
      margin-bottom: 1.75rem;
    }
    h2 {
      margin-top: calc(2vmin + 2rem);
      font-size: 1.5rem;
      font-weight: 600;
    }
    ul,
    ol {
      padding-left: 1.5rem;
      margin-bottom: 2.5rem;
      ul,
      ol {
        margin-bottom: 0;
      }
      li {
        margin-bottom: 0.3rem;
        padding-left: 0.3rem;
        line-height: 1.7;
      }
    }
    ul {
      list-style: disc;
      ul {
        list-style: circle;
      }
    }
    ol {
      list-style: decimal;
    }
  }
`;

const components: Partial<PortableTextReactComponents> = {
  types: {
    table: TextTableSerializer,
    gallery: TextGallerySerializer,
    linkButton: TextLinkButtonSerializer,
    videoEmbed: VideoEmbedSerializer,
  },
  marks: {
    link: (props) => (
      <Link
        textDecoration="none"
        borderBottom={`1px solid ${colors.accentBlueMedium}`}
        _hover={{ color: colors.accentBlueMedium }}
        href={props.value.href}
      >
        {props.children}
      </Link>
    ),
  },
};

interface Props {
  blocks?: RichTextBlock | ExtraRichTextBlock;
}

function BlockContent(props: Props) {
  return (
    <RichTextStyle>
      <PortableText value={(props.blocks as any) || []} components={components} />
    </RichTextStyle>
  );
}

export default BlockContent;
