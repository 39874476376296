import {
  Box,
  Table as ChakraTable,
  TableCaption,
  Tbody,
  Td,
  Th,
  Thead,
  theme,
  Tr,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Table } from "@libry-content/types";
import { PortableTextTypeComponentProps } from "@portabletext/react";
import { PropsWithChildren } from "react";
import styled from "@emotion/styled";

type Props = PropsWithChildren<PortableTextTypeComponentProps<Table>>;

const numColumnCellPaddings: Record<number, string> = {
  1: "2rem",
  2: "2rem",
  3: "1.5rem",
  4: "1.25rem",
};

const StyledTable = styled(ChakraTable)<{ numColumns: number }>`
  th {
    color: inherit;
    text-transform: none;
  }
  td,
  th {
    white-space: nowrap;
    padding: 0.5rem ${({ numColumns }) => numColumnCellPaddings[numColumns] ?? ".5rem"};
    text-align: start;
    border-color: ${theme.colors.gray[200]};
  }
  max-width: ${({ numColumns }) => (numColumns < 5 ? "max-content" : undefined)};
`;

export function TextTableSerializer(props: Props) {
  const { columnHeaders, rows, showRowHeaders, showColumnHeaders, caption } = props.value ?? {};
  const size = useBreakpointValue({ base: "sm", sm: "md" }); // the size-param in table doesn't respond to normal media-query-syntax, so using this instead

  const totalNumberOfColumns = (columnHeaders?.cells?.length ?? 0) + (showRowHeaders ? 1 : 0);
  return (
    <Box overflowX="auto" marginY="3rem" maxW="35rem">
      <StyledTable size={size} numColumns={totalNumberOfColumns}>
        {caption && (
          <TableCaption fontStyle="italic" placement="bottom" opacity={0.8} marginTop={2}>
            {caption}
          </TableCaption>
        )}
        {showColumnHeaders && (
          <Thead>
            <Tr>
              {showRowHeaders && <Th>{columnHeaders?.rowHeader}</Th>}
              {columnHeaders?.cells?.map((cell, i) => (
                <Th key={i}>{cell}</Th>
              ))}
            </Tr>
          </Thead>
        )}
        <Tbody>
          {rows?.map((row) => (
            <Tr key={row._key}>
              {showRowHeaders && <Th>{row.rowHeader}</Th>}
              {row.cells?.map((cell, i) => (
                <Td key={i}>{cell}</Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </StyledTable>
    </Box>
  );
}
