import { AspectRatio } from "@biblioteksentralen/js-utils";
import { VideoEmbed } from "@libry-content/types";

type Props = {
  value?: VideoEmbed;
};

const VideoEmbedSerializer = ({ value }: Props) => {
  if (!value?.embedUrl) return null;

  return (
    <AspectRatio
      maxWidth={{ base: "100%", md: "80%" }}
      ratio={16 / 9}
      borderRadius="0.5rem"
      overflow="hidden"
      margin="4rem auto"
    >
      <iframe src={value.embedUrl} allowFullScreen />
    </AspectRatio>
  );
};

export default VideoEmbedSerializer;
