import {
  ResponsiveArray,
  ResponsiveValue,
  useBreakpointValue as useBreakpointValueChakra,
} from "@biblioteksentralen/js-utils";

// Chakra's useBreakpointValue handles arrays and objects only

type ChakraBreakpointValue<T> = ResponsiveArray<T> | Partial<Record<string, T>>;

const isResponsiveArray = <T extends unknown>(value: ResponsiveValue<T>): value is ResponsiveArray<T> =>
  Array.isArray(value);

const isObjectOrArray = <T extends unknown>(value: ResponsiveValue<T>): value is ChakraBreakpointValue<T> =>
  isResponsiveArray(value) || typeof value === "object";

const useBreakpointValue = <T extends string | number>(value: ResponsiveValue<T>): T => {
  const valueIsArrayOrObject = isObjectOrArray(value);
  const chakraValue = (valueIsArrayOrObject ? value : { base: undefined });
  const valueFromChakra = useBreakpointValueChakra(chakraValue);

  return valueIsArrayOrObject ? valueFromChakra! : value;
};

export default useBreakpointValue