import { TouchEvent, useState } from "react";

type HorizontalSwipeProps = { threshold?: number; onSwipeLeft?: () => void; onSwipeRight?: () => void };

const useHorizontalSwipe = ({ threshold = 50, onSwipeLeft, onSwipeRight }: HorizontalSwipeProps) => {
  const [startX, setStartX] = useState<number | undefined>(undefined);
  const [endX, setEndX] = useState<number | undefined>(undefined);

  const onTouchStart = (event: TouchEvent) => {
    setStartX(event.targetTouches[0].clientX);
  };

  const onTouchMove = (event: TouchEvent) => {
    setEndX(event.targetTouches[0].clientX);
  };

  const deltaX = typeof startX !== "undefined" && typeof endX !== "undefined" ? endX - startX : undefined;

  const onTouchEnd = () => {
    setStartX(undefined);
    setEndX(undefined);
    if (!deltaX) return;
    if (deltaX > threshold) onSwipeLeft?.();
    else if (deltaX < -threshold) onSwipeRight?.();
  };

  return { onTouchStart, onTouchMove, onTouchEnd, deltaX };
};

export default useHorizontalSwipe;
